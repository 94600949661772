@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html {
    font-size: 16px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html, body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

/* Firefox */
* {
    scrollbar-color: hsla(0,0%,0%,0.28);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    height: 9px;
    width: 9px;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 1px hsl(0,0%,100%);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.28);
    border-radius: 7px;
    box-shadow: inset 0 0 0 1px #fff;
}